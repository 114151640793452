import {
  createTheme,
  responsiveFontSizes,
  type ThemeOptions,
} from '@mui/material/styles'
import { componentStyleOverrides } from './override'
import { colors, config } from './config'
import { themeTypography } from './typography'

const themeOptions: ThemeOptions = {
  palette: colors,
  mixins: {
    toolbar: {
      minHeight: '48px',
      padding: '16px',
      '@media (min-width: 600px)': {
        minHeight: '48px',
      },
    },
  },
  shape: {
    borderRadius: config.borderRadius,
  },
  typography: themeTypography(config),
}

const theme = responsiveFontSizes(createTheme(themeOptions))
theme.components = componentStyleOverrides(config, themeOptions)
theme.shadows[0] = 'none'
theme.shadows[1] =
  '0px 0px 6px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.08)'
theme.shadows[2] =
  '0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)'

export { theme }

declare module '@mui/material/styles' {
  interface TypeBackground {
    panel1: string
    panel2: string
    panel3: string
  }
}
